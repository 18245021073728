import axios from "axios";
import { ShowNotification } from "../components/ShowNotificationModal/ShowNotificationModal";


export const axiosInstance = axios.create({
  baseURL: "https://lkp.a101.ru/api",
  headers: {
    "Access-Control-Allow-Methods": "*",
  },
});

axiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});

function containsEntityWithContent(text: string, entities: string[]) {
  for (const entity of entities) {
    const pattern = new RegExp(`${entity}/.+`); // Ищем entity с контентом в любом месте
    if (pattern.test(text)) {
      return true;
    }
  }
  return false;
}


const entities = ["contract", "access-act", "work-completed-act", "invoice", "limit-fence-card","direction-letter", "savings-compensation-act", "construction-control-note", "transfer-note", "order", "supplier-order"];

axiosInstance.interceptors.response.use(
  (response) => {
    return(response)
  },
  (error) => {
    const errorStatus = error.response.status
    const errorMessage = error.response.data.message
    const requestMethod = error.config.method;
    const requestUrl = error.config.url;
    const containsEntity = requestMethod === "get" && containsEntityWithContent(requestUrl, entities)
    if (errorStatus === 401) {
      window.location.href = "/login";
    }

    ShowNotification(errorStatus, errorMessage, containsEntity)
    return Promise.reject(error);
  });